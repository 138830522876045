<template>
  <v-dialog :model-value="props.value" width="auto" min-width="340" persistent>
    <v-card
      title="New Open Private Booking"
      prepend-icon="mdi-plus-box-outline"
    >
      <v-sheet class="d-flex flex-column ma-3">
        <v-form ref="form" @submit.prevent v-if="branchs">
          <auto-complete-member v-model="selectedMember"></auto-complete-member>
          <v-select
            v-model="selectedBranch"
            :rules="RULES.select"
            variant="outlined"
            label="Branch"
            prepend-inner-icon="mdi-store-outline"
            :items="branchs"
            item-title="name"
            item-value="id"
          ></v-select>
          <v-select
            :disabled="!stepReach('BRANCH_SELECTED')"
            v-model="selectedInstructor"
            :rules="RULES.select"
            variant="outlined"
            label="Instructor"
            prepend-inner-icon="mdi-clipboard-account"
            :items="instructors"
            item-title="title"
            item-value="value"
          ></v-select>
          <v-select
            :disabled="!stepReach('INSTRUCTOR_SELECTED')"
            v-model="selectedTopic"
            :rules="RULES.select"
            variant="outlined"
            label="Topic"
            prepend-inner-icon="mdi-book-outline"
            :items="topics"
            item-title="title"
            item-value="value"
          ></v-select>
          <v-date-input
            :disabled="!stepReach('TOPIC_SELECTED')"
            v-model="selectedDate"
            :rules="RULES.notEmpty"
            prepend-icon=""
            prepend-inner-icon="$calendar"
            label="Date"
            variant="outlined"
            :allowed-dates="allowedDates"
          ></v-date-input>
          <v-select
            :disabled="!stepReach('DATE_SELECTED')"
            v-model="selectedTime"
            label="Time"
            :items="times"
            variant="outlined"
            prepend-inner-icon="mdi-clock-outline"
          ></v-select>
        </v-form>
      </v-sheet>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Cancel"
          @click="
            emits('cancelBooking');
            clear();
          "
        ></v-btn>
        <v-btn
          :disabled="!stepReach('TIME_SELECTED')"
          text="Update"
          @click="submitOpenPrivateInfo"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import BackendConnector from "@/util/BackendConnector";
import AutoCompleteMember from "./AutoCompleteMember.vue";
import { ref, defineProps, defineEmits, reactive, watch } from "vue";
import { RULES } from "@/util/Form";
import moment from "moment";

const STEP = [
  "NOTHING_SELECTED",
  "BRANCH_SELECTED",
  "INSTRUCTOR_SELECTED",
  "TOPIC_SELECTED",
  "DATE_SELECTED",
  "TIME_SELECTED",
];
const currentStep = ref(0);

const props = defineProps(["value"]);
const emits = defineEmits(["makeBooking", "cancelBooking"]);

const branchs = reactive([]);
const instructors = reactive([]);
const topics = reactive([]);
const allowedDates = reactive([]);
const times = reactive([]);

const selectedMember = ref(null);
const selectedBranch = ref(null);
const selectedInstructor = ref(null);
const selectedTopic = ref(null);
const selectedDate = ref(null);
const selectedTime = ref(null);

watch(selectedBranch, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  if (newValue == null) {
    return;
  }
  selectedInstructor.value = null;
  selectedTopic.value = null;
  selectedDate.value = null;
  selectedTime.value = null;
  BackendConnector.loadInstructors(newValue).then((response) => {
    Object.assign(instructors, [...response]);
    currentStep.value = STEP.indexOf("BRANCH_SELECTED");
  });
});

watch(selectedInstructor, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  if (newValue == null) {
    return;
  }
  selectedTopic.value = null;
  selectedDate.value = null;
  selectedTime.value = null;
  BackendConnector.loadTopics(newValue).then((response) => {
    Object.assign(topics, [...response]);
    currentStep.value = STEP.indexOf("INSTRUCTOR_SELECTED");
  });
});

watch(selectedTopic, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  if (newValue == null) {
    return;
  }
  selectedDate.value = null;
  selectedTime.value = null;
  BackendConnector.loadOpenPrivateAllowedDate(selectedInstructor.value).then(
    (response) => {
      Object.assign(allowedDates, [...response.data]);
      currentStep.value = STEP.indexOf("TOPIC_SELECTED");
    }
  );
});

watch(selectedDate, (newValue) => {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  if (newValue == null) {
    return;
  }
  selectedTime.value = null;
  BackendConnector.loadOpenPrivateAvailableTime(
    selectedInstructor.value,
    selectedTopic.value,
    moment(newValue).format("YYYY-MM-DD")
  ).then((response) => {
    if (response.length > 0) {
      times.length = 0;
      Object.assign(times, [...response]);
    } else {
      times.length = 0;
    }
    currentStep.value = STEP.indexOf("DATE_SELECTED");
  });
});

watch(selectedTime, () => {
  currentStep.value = STEP.indexOf("TIME_SELECTED");
});

BackendConnector.loadBranch().then((response) => {
  Object.assign(branchs, [...response.data.data]);
});

function submitOpenPrivateInfo() {
  BackendConnector.makeOpenPrivateBooking(
    selectedBranch.value,
    selectedInstructor.value,
    selectedTopic.value,
    moment(selectedDate.value).format("YYYY-MM-DD"),
    selectedTime.value,
    selectedMember.value
  ).then(() => {
    emits("makeBooking");
    clear();
  });
}

function stepReach(step) {
  return currentStep.value >= STEP.indexOf(step);
}

function clear() {
  currentStep.value = STEP.indexOf("NOTHING_SELECTED");
  selectedMember.value = null;
  selectedBranch.value = null;
  selectedInstructor.value = null;
  selectedTopic.value = null;
  selectedDate.value = null;
  selectedTime.value = null;
}

console.log(STEP, emits);
</script>
